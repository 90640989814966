import React from 'react';
import { Link } from 'gatsby';
import { Box, Button, Center, Heading, Image, Text } from '@chakra-ui/react';

import MainLayout from '@components/Layout';

function ErrorNotFound() {
  return (
    <MainLayout>
      <Box padding="42px" height="100vh">
        <Center>
          <Image src="https://user-images.githubusercontent.com/106049860/175778615-f5f80fee-5b17-4ecd-b859-9f566ddc9a94.png" />
        </Center>
        <Heading marginTop="32px" textAlign="center">
          Waduh, tujuanmu nggak ada!
        </Heading>
        <Text textAlign="center">
          Mungkin kamu salah jalan atau alamat. Ayo balik sebelum gelap!
        </Text>
        <Center marginTop="32px">
          <Link to="/">
            <Button color="white" bgColor="#27424c">Back to Homepage</Button>
          </Link>
        </Center>
      </Box>
    </MainLayout>
  );
}

export default ErrorNotFound;
